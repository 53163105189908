<template>
	<div v-if="classData">
		<DashboardHeader
			v-if="classData"
			:title="classData.classTitle"
			:buttonItems="buttonItems"
			@childClick="childClick"
		/>
		<div v-if="classData" class="container-fluid mt--6">
			<b-overlay :show="isLoading" rounded="sm">
				<b-row>
					<b-col>
						<ClassHeaderCard
							:classData="classData"
							@imageClicked="showEditImage = true"
						/>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<Card no-body>
							<CardHeader
								slot="header"
								title="Class Information"
								:end-button="{ title: 'Update Class', disabled: !needUpdateClass }"
								@click="onUpdateClass"
							/>
							<b-tabs
								active-nav-item-class="bg-white font-weight-bold description"
								nav-class="bg-white description"
								card
								no-body
							>
								<b-tab title="Class Details">
									<TitleCard
										:classData="classData"
										:isDisabled="classData.isCancelled"
										@onChange="onClassTitle"
									/>
									<LocationCard
										:classData="classData"
										:isDisabled="classData.isCancelled"
										@onChange="onClassLocation"
									/>
								</b-tab>
								<b-tab title="Price Information">
									<PriceCard
										:classData="classData"
										:isDisabled="classData.isCancelled"
										:showCustomButton="
											classData.customButtonTitle != null ||
											classData.customButtonLink != null
										"
										@onChange="onClassPrice"
									/>
								</b-tab>
								<b-tab title="Registration">
									<RegistrationCard
										:classData="classData"
										:isDisabled="classData.isCancelled"
										@onChange="onClassRegistration"
									/>
								</b-tab>
								<b-tab title="Additional Settings">
									<AdditionalSettingsCard
										:classData="classData"
										@onChange="onClassSettings"
									/>
								</b-tab>
								<b-tab title="Events"  @click="tabClicked(4)" lazy>
									<ClassEventsCard :classData="classData" :refresh="activeTabs.includes(4)" @calendarItemClicked="askToDeleteCalendarItem" />
								</b-tab>
								<b-tab title="Resources" @click="tabClicked(5)">
									<ClassResourcesCard 
										:classData="classData"
										:refresh="activeTabs.includes(5)"
									/>
								</b-tab>
							</b-tabs>
						</Card>
					</b-col>
				</b-row>
			</b-overlay>
		</div>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@close="showQuestionModal = false"
			@yesClicked="deleteClass(false)"
			@noClicked="showQuestionModal = false"
		/>
		<NewNotification
			headerTitle="New Notification"
			:showModal="showNotificationModal"
			:classData="classData"
			@close="showNotificationModal = false"
			@newNotification="onNewNotification"
		/>
		<NewResourceItem
			headerTitle="New Resource"
			:showModal="showNewResouceModal"
			:classData="classData"
			@close="showNewResouceModal = false"
			@newResource="onNewResource"
		/>
		<ChangeImage
			:showModal="showEditImage"
			@updatedImage="addedImage"
			@close="showEditImage = false"
		/>
	</div>
</template>
<script>
import Card from '../../../../components/Cards/Card.vue';
import CardHeader from '../../../../components/Cards/CardHeader.vue';
import { DashboardChildren } from '../../../../routes/routesNames';
import ClassHeaderCard from '../../../Components/ClassesViews/BaseClassView/ClassHeaderCard.vue';
import LocationCard from '../../../Components/GeneralViews/LocationCard.vue';
import PriceCard from '../../../Components/GeneralViews/PriceCard.vue';
import TitleCard from '../../../Components/GeneralViews/TitleCard.vue';
import DashboardHeader from '../../HeaderFooterComponents/DashboardHeader.vue';
import ClassItemManager from '../../../../models/ClassItem/ClassItemManager';
import RegistrationCard from '../../../Components/GeneralViews/RegistrationCard.vue';
import AdditionalSettingsCard from '../../../Components/GeneralViews/AdditionalSettingsCard.vue';
import { mapActions } from 'vuex';
import QuestionModal from '../../../../components/Modals/QuestionModal.vue';
import NewNotification from '../../../mainForms/NewNotification.vue';
import ChangeImage from '../../../mainForms/ChangeImage.vue';
import NewResourceItem from '../../../mainForms/NewResourceItem.vue';
import ClassResourcesCard from '../../../Components/ClassesViews/ClassResourcesCard.vue';
import ClassEventsCard from '../../../Components/ClassesViews/ClassEventsCard.vue';
import CalendarItemManager from '../../../../models/CalendarItem/CalendarItemManager';


export default {
	props: {
		classId: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			isLoading: false,
			classData: null,
			needUpdateClass: false,
			showQuestionModal: false,
			showNotificationModal: false,
			showNewResouceModal: false,
			showEditImage: false,
			calendarItem: null,
			modalQuestion: null,
			titleUpdate: null,
			locationUpdate: null,
			priceUpdate: null,
			registrationUpdate: null,
			settingsUpdate: null,
			selectedId: null,
			viewAttendeesId: 0,
			viewPendingAttendeesId: 1,
			sendNotificationId: 2,
			shareClassId: 3,
			deleteClassId: 4,
			sendResourceId: 5,
			activeTabs: [],
			buttonItems: [
				{
					index: 0,
					title: 'Options',
					type: 'primary',
					children: [],
				},
			],
		};
	},
	watch: {
		titleUpdate: {
			handler() {
				return this.checkIfUpdateNeed();
			},
		},
		locationUpdate: {
			handler() {
				return this.checkIfUpdateNeed();
			},
		},
		priceUpdate: {
			handler() {
				return this.checkIfUpdateNeed();
			},
		},
		registrationUpdate: {
			handler() {
				return this.checkIfUpdateNeed();
			},
		},
		settingsUpdate: {
			handler() {
				return this.checkIfUpdateNeed();
			},
		},
	},
	methods: {
		...mapActions(['showNotification']),
		setButtons() {
			this.buttonItems[0].children = [
				{
					index: this.viewAttendeesId,
					title: 'View Members',
				},
				/*
				{
					index: this.sendNotificationId,
					title: 'Send Notification',
					isDisabled: true,
				},
				*/
				{
					index: -1,
					title: '',
					isDivider: true,
				},
				{
					index: this.shareClassId,
					title: 'Share Class',
				},
				{
					index: -2,
					title: '',
					isDivider: true,
				},
				{
					index: this.deleteClassId,
					title: 'Delete Class',
					type: 'danger',
				},
			];
		},
		getClassData() {
			this.isLoading = true;
			ClassItemManager.getClassById(this.classId).then((result) => {
				if (result) {
					this.classData = result;
					this.setButtons();
					this.isLoading = false;
				} else {
					this.navigateToClasses();
				}
			});
		},
		tabClicked(index) {
			if (!this.activeTabs.find((i) => i == index)) {
				this.activeTabs.push(index);
			}
		},
		addedImage(file) {
			const updatedImage = file[0];
			this.isLoading = true;
			ClassItemManager.changeClassImage(updatedImage, this.classData)
				.then(() => {
					this.isLoading = false;
					this.getClassData();
					this.showNotification({
						type: 'success',
						message: 'Class Updated.',
					});
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: error, //'Unknown Error.',
					});
				});
		},
		askDeleteQuestion() {
			this.modalQuestion =
				'Are you sure you want to delete this class? This action cannot be undone.';
			this.showQuestionModal = true;
		},
		askToDeleteCalendarItem(calendarItem) {
			this.calendarItem = calendarItem;
			this.modalQuestion = 'Do you want to delete this calendar item?';
			this.showQuestionModal = true;
		},
		deleteCalendarItem() {
			this.isLoading = true;
			CalendarItemManager.deleteCalendarItem(this.calendarItem.calendarId, this.classData.classId)
				.then(() => {
					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: 'Calendar Item has been deleted.',
					});
					this.resetView();
				})
				.catch((errorMessage) => {
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		openNewNotification() {
			this.showNotificationModal = true;
		},
		deleteClass() {
			this.isLoading = true;
			ClassItemManager.deleteClass(this.classId)
				.then(() => {
					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: 'Class Deleted',
					});
					this.navigateToClasses();
				})
				.catch((errorMessage) => {
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		shareClass() {
			if (this.classData.shareLink) {
				navigator.clipboard.writeText(this.classData.shareLink);
				this.showNotification({
					type: 'success',
					message: 'Link Copied',
				});
			} else {
				this.showNotification({
					type: 'danger',
					message: 'Unable To Copy Link',
				});
			}
		},
		onNewNotification(notification) {
			this.showNotificationModal = false;
			this.showNotification({
				type: 'success',
				message: 'Notification Sent.',
			});
		},
		onNewResource(resource) {
			this.allResources.push(resource);
			this.showNotification({
				type: 'success',
				message: `${resource.resourceTitle} Added.`,
			});
		},
		onClassTitle(data) {
			this.titleUpdate = data.isDifferent ? data : null;
		},
		onClassLocation(data) {
			this.locationUpdate = data.isDifferent ? data : null;
		},
		onClassPrice(data) {
			this.priceUpdate = data.isDifferent ? data.price : null;
		},
		onClassRegistration(data) {
			this.registrationUpdate = data.isDifferent ? data.registration : null;
		},
		onClassSettings(data) {
			this.settingsUpdate = data.isDifferent ? data.settings : null;
		},
		checkIfUpdateNeed() {
			this.needUpdateClass =
				this.titleUpdate ||
				this.locationUpdate ||
				this.priceUpdate ||
				this.registrationUpdate ||
				this.settingsUpdate;
		},
		onUpdateClass() {
			var updatedData = {};
			if (this.titleUpdate) {
				if (this.titleUpdate.title) {
					updatedData['classTitle'] = this.titleUpdate.title;
				}
				if (this.titleUpdate.description) {
					updatedData['classDescription'] = this.titleUpdate.description;
				}
				if (this.titleUpdate.start) {
					updatedData['classStart'] = new Date(this.titleUpdate.start);
				}
				if (this.titleUpdate.end){
					updatedData['classEnd'] = new Date(this.titleUpdate.end)
				}
				if (this.titleUpdate.recurrenceDays){
					if (this.classData.recurrence){
						var recurrence = this.classData.recurrence
						recurrence['recurrenceDays'] = this.titleUpdate.recurrenceDays
						updatedData['recurrence'] = recurrence
					}
				}
				if (this.titleUpdate.category){
					updatedData['categoryId'] = this.titleUpdate.category.categoryId
				}
			}
			if (this.locationUpdate) {
				updatedData['location'] = this.locationUpdate;
			}
			if (this.priceUpdate) {
				updatedData['customButtonTitle'] = this.priceUpdate.eventButtonTitle;
				updatedData['customButtonLink'] = this.priceUpdate.eventButtonLink;
			}
			if (this.registrationUpdate) {
				updatedData['classMaxUsers'] = this.registrationUpdate.eventMaxUsers;
				updatedData['registrationStartDuration'] =
						this.registrationUpdate.registrationStartDuration;
				updatedData['registrationCloseDuration'] =
						this.registrationUpdate.registrationCloseDuration;
				if (this.registrationUpdate.startAge != null) {
					var ageItem = {
						startAge: this.registrationUpdate.startAge,
						enforced: this.registrationUpdate.enforced,
					};
					if (this.registrationUpdate.endAge) {
						ageItem['endAge'] = this.registrationUpdate.endAge;
					}
					updatedData['ageItem'] = ageItem;
				} else {
					updatedData['ageItem'] = null
				}
			}
			if (this.settingsUpdate) {
				if (this.settingsUpdate.isPrivate != null) {
					updatedData['isPrivate'] = this.settingsUpdate.isPrivate;
				}
				if (this.settingsUpdate.isLocked != null) {
					updatedData['isLocked'] = this.settingsUpdate.isLocked;
				}
				if (this.settingsUpdate.requiredDocuments != null) {
					updatedData['requiredDocuments'] = this.settingsUpdate.requiredDocuments;
				}
				if (this.settingsUpdate.showOnWebsite != null){
					updatedData['showOnWebsite'] = this.settingsUpdate.showOnWebsite;
				}
				if (this.settingsUpdate.isOnCalendar != null) {
					updatedData['isOnCalendar'] = this.settingsUpdate.isOnCalendar;
				}
				if (this.settingsUpdate.facultyLeaders) {
					updatedData['classLeaders'] = [this.settingsUpdate.facultyLeaders];
				} else {
					//updatedData['eventLeaders'] = deleteField()
				}
				if (this.settingsUpdate.calendarHex) {
					updatedData['calendarHex'] = this.settingsUpdate.calendarHex;
				}
				if (this.settingsUpdate.eventPolicy) {
					updatedData['classPolicy'] = this.settingsUpdate.eventPolicy;
				}
			}
			this.isLoading = true;
			ClassItemManager.updateClass(this.classData, updatedData)
				.then(() => {
					this.isLoading = false;
					this.getClassData();
					this.showNotification({
						type: 'success',
						message: 'Class Updated.',
					});
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: 'Unknown Error.',
					});
				});
		},
		childClick(childClicked) {
			this.selectedId = childClicked.index;
			switch (childClicked.index) {
				case this.viewAttendeesId:
					this.$router.push({
						name: DashboardChildren.classViewUsers.name,
						params: {
							classData: this.classData,
							classId: this.classId,
						},
						query: {
							classId: this.classId,
						},
					});
					break;
				case this.sendNotificationId:
					this.openNewNotification();
					break;
				case this.shareClassId:
					this.shareClass();
					break;
				case this.deleteClassId:
					if (
						this.classData.price != null &&
						(this.classData.classUsers ? this.classData.classUsers : 0 > 0)
					) {
						this.showNotification({
							type: 'danger',
							message: 'Please remove all users before deleting this class.',
						});
					} else {
						this.askDeleteQuestion();
					}

					break;
				default:
					break;
			}
		},
		navigateToClasses() {
			this.$router.replace({
				name: DashboardChildren.classes.name,
				params: { refresh: true },
			});
		},
	},
	mounted() {
		if (!this.classId) {
			this.navigateToClasses();
		} else if (this.$route.params.classData) {
			this.classData = this.$route.params.classData;
			this.setButtons();
		} else {
			this.getClassData();
		}
	},
	components: {
    DashboardHeader,
    ClassHeaderCard,
    Card,
    CardHeader,
    TitleCard,
    LocationCard,
    PriceCard,
    RegistrationCard,
    AdditionalSettingsCard,
    QuestionModal,
    NewNotification,
    ChangeImage,
    NewResourceItem,
	ClassResourcesCard,
	ClassEventsCard
},
};
</script>
