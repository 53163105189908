<template>
	<div>
		<DashboardHeader
			:title="this.$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
		<div class="container-fluid mt--6">
			<b-overlay :show="isLoading" rounded="sm">
				<Card no-body>
					<b-tabs
						active-nav-item-class="bg-white font-weight-bold description"
						nav-class="bg-white description"
						card
						no-body
					>
						<b-tab title="Class Details" active>
							<NewClassDetailsCard @onChange="onClassDetails" />
							<NewClassTimeCard @onChange="onClassTime" />
							<LocationCard @onChange="onClassLocation" :isNew="true" />
						</b-tab>
						<b-tab title="Price Information" v-if="appInformation.isPaymentActive">
							<PriceCard
								@onChange="onClassPrice"
								:showOneTimeOption="false"
								:price="classPrice"
								:isNewClass="true"
							/>
						</b-tab>
						<b-tab title="Registration">
							<RegistrationCard
								@onChange="onClassRegistration"
								:showMaxUsers="true"
								:isNewClass="true"
							/>
						</b-tab>
						<b-tab title="Additional Settings">
							<AdditionalSettingsCard @onChange="onClassSettings" :isNewClass="true" />
						</b-tab>
					</b-tabs>
				</Card>
			</b-overlay>
		</div>
	</div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';
import DashboardHeader from '../../mainViews/HeaderFooterComponents/DashboardHeader.vue';
import NewClassDetailsCard from './NewClassDetailsCard.vue';
import NewClassTimeCard from './NewClassTimeCard.vue';
import LocationCard from '../GeneralViews/LocationCard.vue';
import PriceCard from '../GeneralViews/PriceCard.vue';
import RegistrationCard from '../GeneralViews/RegistrationCard.vue';
import AdditionalSettingsCard from '../GeneralViews/AdditionalSettingsCard.vue';
import ClassItemManager from '../../../models/ClassItem/ClassItemManager';
import { mapActions, mapGetters } from 'vuex';
import { DashboardChildren } from '../../../routes/routesNames';

export default {
	computed: {
		...mapGetters({
			appInformation: 'getAppInformation',
		}),
	},
	components: {
		DashboardHeader,
		Card,
		NewClassDetailsCard,
		NewClassTimeCard,
		LocationCard,
		PriceCard,
		RegistrationCard,
		AdditionalSettingsCard,
	},
	data() {
		return {
			isLoading: false,
			buttonItems: [
				{
					index: 0,
					title: 'Save & Publish',
					type: 'primary',
					isDisabled: true,
				},
			],
			validItems: {
				classDetails: false,
				classDateTime: false,
				classLocation: true,
				classPrice: false,
				classRegistration: true,
				classSettings: true,
			},
			classDetails: {
				classTitle: null,
				classDescription: null,
				classImage: null,
				categoryId: null,
			},
			classDateTime: {
				classStartDate: null,
				classDuration: null,
				classRecurrence: null,
				classRecurrenceDays: [],
				classEndDate: null,
			},
			classLocation: {
				title: null,
				description: null,
			},
			classPrice: {
				isFree: false,
				isPrepaidOption: true,
				subscriptionBilling: true,
				oneTimePurchase: false,
				oneTimePuchaseAmount: 0,
				amount: 0,
				subscription: {
					recurrence: {
						recurrenceTime: null,
					},
					subscriptionEnd: null,
					subscriptionStart: null,
				},
				quantity: null,
				eventButtonTitle: null,
				eventButtonLink: null,
				eventCurrency: 'US Dollar - USD ($)',
				transactionFee: 0,
			},
			classRegistration: {
				isPrivate: true,
				eventMaxUsers: null,
				registrationStartDuration: null,
				registrationCloseDuration: null,
				startAge: null,
				endAge: null,
				enforced: false,
			},
			classSettings: {
				isFeatured: false,
				isPrivate: false,
				isOnCalendar: true,
				requiredDocuments: true,
				showOnWebsite: true,
				facultyLeaders: null,
				eventPolicy: null,
				calendarHex: null,
			},
		};
	},
	methods: {
		...mapActions(['showNotification']),
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.onSave();
			}
		},
		onClassTime(data) {
			this.classDateTime = data;
			if (
				this.classDateTime.classStartDate &&
				this.classDateTime.classDuration > 0 &&
				this.classDateTime.classRecurrence != null
			) {
				this.validItems.classDateTime = true;
			} else if (
				this.classDateTime.classRecurrence &&
				this.classDateTime.classStartDate &&
				this.classDateTime.classDuration > 0 &&
				this.classDateTime.classEndDate &&
				this.classDateTime.classEndDate > this.classDateTime.classStartDate
			) {
				this.validItems.classDateTime = true;
			} else {
				this.validItems.classDateTime = false;
			}
			this.checkIfReady();
		},
		onClassDetails(data) {
			this.classDetails = data;
			if (
				this.classDetails.classTitle &&
				this.classDetails.classDescription &&
				this.classDetails.classImage
			) {
				this.validItems.classDetails = true;
			} else {
				this.validItems.classDetails = false;
			}
			this.checkIfReady();
		},
		onClassLocation(data) {
			this.classLocation = data;
			this.checkIfReady();
		},
		onClassPrice(data) {
			this.classPrice = data.price;
			if (this.classPrice.isFree && !this.classPrice.oneTimePurchase) {
				this.validItems.classPrice = true;
			} else if (
				!this.classPrice.subscriptionBilling &&
				this.classPrice.oneTimePurchase &&
				this.classPrice.oneTimePuchaseAmount > 1
			) {
				this.validItems.classPrice = true;
			} else if (this.classPrice.amount < 1) {
				this.validItems.classPrice = false;
			} else if (
				this.classPrice.subscription.recurrence.recurrenceTime == null ||
				this.classPrice.subscription.subscriptionStart == null ||
				this.classPrice.subscription.subscriptionEnd == null
			) {
				this.validItems.classPrice = false;
			} else if (
				this.classPrice.oneTimePurchase &&
				this.classPrice.oneTimePuchaseAmount < 1
			) {
				this.validItems.classPrice = false;
			} else {
				this.validItems.classPrice = true;
			}
			this.checkIfReady();
		},
		onClassRegistration(data) {
			this.classRegistration = data.registration;
			this.checkIfReady();
		},
		onClassSettings(data) {
			this.classSettings = data.settings;
			this.checkIfReady();
		},
		checkIfReady() {
			const isValid = Object.values(this.validItems).every(
				(value) => value === true
			);
			this.buttonItems[0].isDisabled = !isValid;
		},
		onSave() {
			const classDetails = this.classDetails;
			const classDateTime = this.classDateTime;
			const classLocation = this.classLocation;
			const classPrice = this.classPrice;
			const classRegistration = this.classRegistration;
			const classSettings = this.classSettings;

			var classData = {
				uploadTimestamp: this.$helpers.returnTimestamp(),
				classTitle: classDetails.classTitle,
				classDescription: classDetails.classDescription,
				classStart: this.$helpers.changeTimeZone(
					new Date(classDateTime.classStartDate)
				),
				classDuration: parseInt(classDateTime.classDuration),
				location: {
					title: classLocation.title,
					description: classLocation.description,
				},
				isPrivate: classSettings.isPrivate,
				isOnCalendar: classSettings.isOnCalendar,
				calendarHex: classSettings.calendarHex,
				requiredDocuments: classSettings.requiredDocuments,
				showOnWebsite: classSettings.showOnWebsite
			};

			if (classDetails.categoryId) {
				classData['categoryId'] = classDetails.categoryId;
			}

			if (classDateTime.classRecurrence) {
				const classEnd = this.$helpers.changeTimeZone(
					new Date(classDateTime.classEndDate)
				);
				const recurrenceTime = classDateTime.classRecurrence;
				var recurrence = {
					recurrenceTime: recurrenceTime,
				};
				if (classDateTime.classRecurrenceDays) {
					var recurrenceDays = [];
					if (classDateTime.classRecurrenceDays.length > 0) {
						recurrenceDays = classDateTime.classRecurrenceDays;
					}
					if (
						classDateTime.classRecurrence == 'everyDay' ||
						classDateTime.classRecurrence == 'everyMonth' ||
						classDateTime.classRecurrence == 'everyYear'
					) {
						recurrenceDays = [];
					}
					recurrence['recurrenceDays'] = recurrenceDays;
				}
				classData['recurrence'] = recurrence;
				classData['classEnd'] = classEnd;
			} else {
				classData['classEnd'] = classData.classStart;
			}

			if (classRegistration.eventMaxUsers) {
				classData['classMaxUsers'] = parseInt(classRegistration.eventMaxUsers);
			}

			if (!classPrice.isFree) {
				var price = {
					amount: classPrice.amount * 100,
				};
				if (classPrice.subscription.recurrence.recurrenceTime) {
					var subscription = {
						amount: price.amount,
						recurrence: {
							recurrenceTime: classPrice.subscription.recurrence.recurrenceTime,
						},
						subscriptionStart: this.$helpers.changeTimeZone(
							new Date(classPrice.subscription.subscriptionStart)
						),
					};
					if (classPrice.subscription.subscriptionEnd) {
						subscription['subscriptionEnd'] = this.$helpers.changeTimeZone(
							new Date(classPrice.subscription.subscriptionEnd)
						);
					}
					price['subscription'] = subscription;
					price.amount = 0;
				}
				if (classPrice.quantity) {
					price['quantity'] = parseInt(classPrice.quantity);
				}
				if (classPrice.oneTimePurchase && classPrice.oneTimePuchaseAmount > 0) {
					price.amount = classPrice.oneTimePuchaseAmount * 100;
					price['oneTimePurchase'] = true;
				}
				classData['isPrepaidOption'] = classPrice.isPrepaidOption;
				classData['price'] = price;
			}

			if (classPrice.eventButtonTitle) {
				classData['customButtonTitle'] = classPrice.eventButtonTitle;
				if (classPrice.eventButtonLink) {
					classData['customButtonLink'] = classPrice.eventButtonLink;
				}
			}

			if (classPrice.transactionFee) {
				classData['transactionFee'] = classPrice.transactionFee * 100;
			}

			if (classRegistration.startAge) {
				var ageItem = {
					startAge: parseInt(classRegistration.startAge),
					enforced: classRegistration.enforced,
				};
				if (classRegistration.endAge) {
					ageItem['endAge'] = parseInt(classRegistration.endAge);
				}
				classData['ageItem'] = ageItem;
			}

			if (classSettings.eventPolicy) {
				classData['classPolicy'] = classSettings.eventPolicy;
			}

			if (classSettings.facultyLeaders) {
				classData['classLeaders'] = [classSettings.facultyLeaders]; //should be array
			}

			if (classSettings.isFeatured) {
				classData['isFeatured'] = classSettings.isFeatured;
			}

			if (classRegistration.registrationStartDuration) {
				classData['registrationStartDuration'] =
					classRegistration.registrationStartDuration;
			}

			if (classRegistration.registrationCloseDuration) {
				classData['registrationCloseDuration'] =
					classRegistration.registrationCloseDuration;
			}

			this.isLoading = true;
			ClassItemManager.addClass(classDetails.classImage[0], classData)
				.then((classData) => {
					ClassItemManager.getAllClasses();
					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: 'New Class has been added.',
					});
					this.navigateToClasses();
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		navigateToClasses() {
			this.$router.replace({
				name: DashboardChildren.classes.name,
				params: { refresh: true },
			});
		},
	},
	mounted() {
		this.classPrice.isFree = !this.appInformation.isPaymentActive;
		this.validItems.classPrice = !this.appInformation.isPaymentActive;
	},
};
</script>
