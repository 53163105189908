import {
	collection,
	doc,
	FieldValue,
	getDoc,
	serverTimestamp,
} from 'firebase/firestore';
import { FirebaseDynamicLinks } from 'firebase-dynamic-links';
import store from '../../store/store';
import { deleteObject, getDownloadURL, listAll, uploadBytesResumable } from 'firebase/storage';

export default class FirebaseManager {
	static accountRef(firestore) {
		return collection(firestore, 'account');
	}
	static accountAppInformationRef(firestore) {
		return doc(firestore, this.accountRef(firestore).id, 'appInformation');
	}
	static accountPaymentInformationRef(firestore) {
		return doc(firestore, this.accountRef(firestore).id, 'paymentInformation');
	}
	static accountPublicInformationRef(firestore) {
		return doc(firestore, this.accountRef(firestore).id, 'publicInformation');
	}
	static accountSocialInformationRef(firestore) {
		return doc(firestore, this.accountRef(firestore).id, 'socialInformation');
	}
	static accountBookingInformationRef(firestore) {
		return doc(firestore, this.accountRef(firestore).id, 'bookingInformation');
	}
	static accountWebsiteInformationRef(firestore) {
		return doc(firestore, this.accountRef(firestore).id, 'websiteInformation');
	}
	static bookingTypeInformationRef(firestore){
		return collection(firestore, `${this.accountBookingInformationRef(firestore).path}/bookingTypes`)
	}
	static allUserRef(firestore) {
		return collection(firestore, 'users');
	}
	static allReportsRef(firestore) {
		return collection(firestore, 'reports');
	}
	static allUsersReportsRef(firestore) {
		return collection(firestore, 'reports/users/reports');
	}
	static allEventReportsRef(firestore) {
		return collection(firestore, 'reports/events/reports');
	}
	static allClassReportsRef(firestore){
		return collection(firestore, 'reports/classes/reports');
	}
	static allProductReportsRef(firestore){
		return collection(firestore, 'reports/products/reports');
	}
	static allMessageChannelRef(firestore) {
		return collection(firestore, 'messageChannels');
	}
	static allNotificationsRef(firestore) {
		return collection(firestore, 'notifications');
	}
	static allAdminNotificationsRef(firestore) {
		return collection(firestore, 'adminNotifications');
	}
	static allFeaturedRef(firestore) {
		return collection(firestore, 'featured');
	}
	static allCategoriesRef(firestore) {
		return collection(firestore, 'categories');
	}
	static allClassesRef(firestore) {
		return collection(firestore, 'classes');
	}
	static allEventsRef(firestore) {
		return collection(firestore, 'events');
	}
	static allProductRef(firestore) {
		return collection(firestore, 'products');
	}
	static allCalendarItemsRef(firestore) {
		return collection(firestore, 'calendarItems');
	}
	static allCouponsRef(firestore) {
		return collection(firestore, 'coupons');
	}
	static allVideosRef(firestore) {
		return collection(firestore, 'videos');
	}
	static allFilesRef(firestore) {
		return collection(firestore, 'files');
	}
	static allResourceRef(firestore) {
		return collection(firestore, 'resources');
	}
	static allBookingRef(firestore) {
		return collection(firestore, 'booking');
	}
	static timestamp() {
		return serverTimestamp();
	}

	static uploadFile(storageRef, file, fileType, loadingCallback) {
		return new Promise((resolve, reject) => {
			const metaData = {
				contentType: fileType,
			};
			const uploadTask = uploadBytesResumable(storageRef, file, metaData);

			// Listen for state changes, errors, and completion of the upload.
			uploadTask.on(
				'state_changed',
				(snapshot) => {
					// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
					const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

					loadingCallback(progress);
					switch (snapshot.state) {
						case 'paused':
							console.log('Upload is paused');
							break;
						case 'running':
							console.log('Upload is running');
							break;
					}
				},
				(error) => {
					// A full list of error codes is available at
					// https://firebase.google.com/docs/storage/web/handle-errors
					return reject(error.message);
				},
				() => {
					// Upload completed successfully, now we can get the download URL
					getDownloadURL(uploadTask.snapshot.ref)
						.then((downloadURL) => {
							return resolve(downloadURL);
						})
						.catch((error) => {
							return reject(error.message);
						});
				}
			);
		});
	}

	static deleteStorageRef(storageRef) {
		return new Promise((resolve, reject) => {
			listAll(storageRef).then((res) => {
				const items = res.items;
				const total = items.length;
				var counter = 0;
				if (total == counter) {
					return resolve();
				} else {
					items.forEach((storageR) => {
						deleteObject(storageR).finally(() => {
							if (counter == total - 1) {
								return resolve();
							}
							counter += 1;
						});
					});
				}
			});
		}).catch((error) => {
			return resolve();
		});
	}

	static createDynamicLink(urlExtended, socialLinkTitle, socialLinkImageUrl) {
		const organization = store.state.organization;
		const publicInformation = store.state.accountInformation.publicInformation;
		const firebaseDynamicLinks = new FirebaseDynamicLinks(organization.apiKey);
		const appLogo = publicInformation.appLogo;
		const appleBundle = publicInformation.iosBundle;
		const appStoreId = publicInformation.appStoreId;
		const androidBundle = publicInformation.androidBundle;
		const appDynamicLink = publicInformation.appDynamicLink;

		const socialDescription = `${organization.appName} app allows me to stay connected with our studio and I want to extend the invitation to you!`;

		var dynamicLinkInfo = {
			domainUriPrefix: appDynamicLink,
			link: `https://${appDynamicLink}/${urlExtended}`,
			navigationInfo: {
				enableForcedRedirect: true,
			},
			socialMetaTagInfo: {
				socialTitle: socialLinkTitle,
				socialDescription: socialDescription,
				socialImageLink: socialLinkImageUrl ? socialLinkImageUrl : appLogo,
			},
		};

		var androidInfo = {};
		if (androidBundle) {
			androidInfo['androidPackageName'] = androidBundle;
		}

		var iosInfo = {};
		if (appleBundle) {
			iosInfo['iosBundleId'] = appleBundle;
		}
		if (appStoreId) {
			iosInfo['iosAppStoreId'] = appStoreId;
		}

		dynamicLinkInfo['androidInfo'] = androidInfo;
		dynamicLinkInfo['iosInfo'] = iosInfo;

		return new Promise((resolve, reject) => {
			firebaseDynamicLinks
				.createLink({ dynamicLinkInfo })
				.then((linkResponse) => {
					return resolve(linkResponse.shortLink);
				})
				.catch((error) => {
					console.log(error);
					return resolve(null);
				});
		});
	}
}
