<template>
	<div>
		<Card :no-body="!facultySwitch">
			<CardHeader
				slot="header"
				title="Faculty Leaders"
				:endSwitch="{ title: '' }"
				:endSwitchValue="facultySwitch"
				@change="facultySwitchChanged"
			/>

			<b-row v-if="facultySwitch">
				<b-col>
					<BaseInput
						id="event leader"
						label="Faculty Leader"
						placeholder="Select Leader"
						:required="facultySwitch"
					>
						<select
							class="form-control"
							id="eventFacultyLeader"
							v-model="settings.facultyLeaders"
						>
							<option
								v-if="isNew ? true : settings.facultyLeaders == null"
								:value="null"
								disabled
								selected
							>
								Choose a leader...
							</option>
							<option
								v-for="leader in facultyLeaders"
								:key="leader.uid"
								:value="leader.uid"
							>
								{{ leader.firstName }} {{ leader.lastName }}
							</option>
						</select>
					</BaseInput>
				</b-col>
			</b-row>
		</Card>
		<Card v-if="classData != null || isNewClass" :no-body="true">
			<CardHeader
				slot="header"
				title="Class Privacy"
				:endSwitch="{ title: 'Is Private?' }"
				:endSwitchValue="settings.isPrivate"
				@change="privacySwitchChanged"
			/>
		</Card>
		<Card v-if="classData != null || isNewClass" :no-body="true">
			<CardHeader
				slot="header"
				title="Show On Calendar"
				:endSwitch="{ title: '' }"
				:endSwitchValue="settings.isOnCalendar"
				@change="isOnCalendarSwitchChanged"
			/>
		</Card>

		<Card v-if="isWebsiteActive()" :no-body="true">
			<CardHeader
				slot="header"
				title="Show On Website"
				:endSwitch="{ title: '' }"
				:endSwitchValue="settings.showOnWebsite"
				@change="showOnWebsiteSwitchChanged"
			/>
		</Card>

		<Card v-if="classData != null || isNewClass" :no-body="true">
			<CardHeader
				slot="header"
				title="Class Posts"
				:endSwitch="{ title: 'Is Locked?' }"
				:endSwitchValue="settings.isLocked"
				@change="lockedSwitchChanged"
			/>
		</Card>
		<Card v-if="eventData == null" :no-body="true">
			<CardHeader
				slot="header"
				title="Feature This Item"
				:endSwitch="{ title: '' }"
				:endSwitchValue="settings.isFeatured"
				@change="eventFeaturedSwitchChanged"
			/>
		</Card>
		<Card :no-body="true">
			<CardHeader
				slot="header"
				title="Required Documents"
				:endSwitch="{ title: '' }"
				:endSwitchValue="settings.requiredDocuments"
				@change="requiredDocumentsSwitchChanged"
			/>
		</Card>

		<Card :no-body="true">
			<div slot="header">
				<b-row>
					<b-col md="8" lg="8" class="d-flex align-items-center" style="gap: 5px">
						<h3 class="mb-0">Select A Calendar Color</h3>
					</b-col>
					<b-col class="d-flex justify-content-end">
						<verte
							v-if="colorMounted"
							:enableAlpha="false"
							:key="x"
							model="hex"
							menuPosition="right"
							v-model="settings.calendarHex"
						/>
					</b-col>
				</b-row>
			</div>
		</Card>

		<Card v-if="false" :no-body="!eventPolicySwitch">
			<CardHeader
				slot="header"
				title="Policy (optional)"
				tooltip="Guests will see this policy at the bottom of their reservation/ticket. Include any
							important info they need to know about your refund policy, special instructions, etc."
				:endSwitch="{ title: null }"
				:endSwitchValue="eventPolicySwitch"
				@change="eventPolicySwitchChanged"
			/>
			<div v-if="eventPolicySwitch">
				<b-row>
					<b-col>
						<BaseInput
							label="Policy Description"
							id="quantity available"
							:placeholder="eventPolicyHint"
							v-model="settings.eventPolicy"
							rows="4"
							type="textarea"
						/>
					</b-col>
				</b-row>
			</div>
		</Card>
	</div>
</template>
<script>
import verte from 'verte';
import 'verte/dist/verte.css';
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import AppUserManager from '../../../models/AppUser/AppUserManager';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import store from '../../../store/store';
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters({
			websiteInformation: 'getWebsiteInformation',
		}),
	},
	components: { Card, CardHeader, verte, BaseInput },
	props: {
		isNew: false,
		isNewClass: false,
		eventData: null,
		classData: null,
	},
	data() {
		return {
			x: 0,
			facultySwitch: true,
			eventPolicySwitch: false,
			colorMounted: false,
			facultyLeaders: [], 
			documents: [],
			eventPolicyHint:
				'This is your event ticket. Ticket holders must present their tickets on entry. You can either print your ticket or present this digital version. You can find all the details about this event on our website. If you have any questions, issues or wish to get a refund, contact the event host. If you can’t attend the event, please get in touch. Looking forward to seeing you there!',

			settings: {
				isFeatured: false,
				isPrivate: false,
				isLocked: false,
				isOnCalendar: true,
				requiredDocuments: true,
				showOnWebsite: true,
				facultyLeaders: null,
				eventPolicy: null,
				calendarHex: null,
			},
		};
	},
	watch: {
		settings: {
			handler() {
				return this.checkIfUpdate();
			},
			deep: true,
		},
	},
	methods: {
		facultySwitchChanged(value) {
			this.facultySwitch = value;
		},
		privacySwitchChanged(value) {
			this.settings.isPrivate = value;
		},
		lockedSwitchChanged(value) {
			this.settings.isLocked = value;
		},
		isOnCalendarSwitchChanged(value) {
			this.settings.isOnCalendar = value;
		},
		eventFeaturedSwitchChanged(value) {
			this.settings.isFeatured = value;
		},
		eventPolicySwitchChanged(value) {
			this.eventPolicySwitch = value;
		},
		requiredDocumentsSwitchChanged(value) {
			this.settings.requiredDocuments = value;
		},
		showOnWebsiteSwitchChanged(value){
			this.settings.showOnWebsite = value;
		},
		isWebsiteActive(){
			if (this.websiteInformation != null){
				return this.websiteInformation.isActive
			}
			return false 
		},
		checkIfUpdate() {
			if (this.eventData) {
				const isDifferent = () => {
					const requiredDocumentsCheck =
						this.settings.requiredDocuments != this.eventData.requiredDocuments;
					const showOnWebsite = this.settings.showOnWebsite != (this.eventData.showOnWebsite != null ? this.eventData.showOnWebsite : true);	
					const facultyLeaderCheck = this.eventData.eventLeaders
						? this.settings.facultyLeaders != this.eventData.eventLeaders[0]
						: this.settings.facultyLeaders != null;
					const featuredCheck =
						this.settings.isFeatured != this.eventData.isFeatured;
					const calendarColorCheck = this.eventData.calendarHex
						? this.settings.calendarHex.toLowerCase() !=
						  this.eventData.calendarHex.toLowerCase()
						: false;
					return (
						requiredDocumentsCheck ||
						showOnWebsite ||
						facultyLeaderCheck ||
						featuredCheck ||
						calendarColorCheck
					);
				};
				this.$emit('onChange', {
					settings: this.settings,
					isDifferent: isDifferent(),
				});
			} else if (this.classData) {
				const isDifferent = () => {
					const requiredDocumentsCheck =
						this.settings.requiredDocuments != this.classData.requiredDocuments;
					const showOnWebsite = this.settings.showOnWebsite != (this.classData.showOnWebsite != null ? this.classData.showOnWebsite : true);	
					const facultyLeaderCheck = this.classData.classLeaders
						? this.settings.facultyLeaders != this.classData.classLeaders[0]
						: this.settings.facultyLeaders != null;
					const featuredCheck =
						this.settings.isFeatured != this.classData.isFeatured;
					const calendarColorCheck = this.classData.calendarHex
						? this.settings.calendarHex.toLowerCase() !=
						  this.classData.calendarHex.toLowerCase()
						: false;
					const classPrivacyCheck =
						this.settings.isPrivate !=
						(this.classData.isPrivate != null ? this.classData.isPrivate : false);
					const classLockedCheck = this.settings.isLocked !=
						(this.classData.isLocked != null ? this.classData.isLocked : false);
					const classIsOnCalendarCheck =
						this.settings.isOnCalendar !=
						(this.classData.isOnCalendar != null
							? this.classData.isOnCalendar
							: true);

					return (
						requiredDocumentsCheck ||
						showOnWebsite ||
						facultyLeaderCheck ||
						featuredCheck ||
						calendarColorCheck ||
						classPrivacyCheck ||
						classLockedCheck ||
						classIsOnCalendarCheck
					);
				};
				this.$emit('onChange', {
					settings: this.settings,
					isDifferent: isDifferent(),
				});
			} else {
				this.onChange();
			}
		},
		onChange() {
			this.$emit('onChange', { settings: this.settings });
		},
	},
	mounted() {
		this.facultyLeaders = AppUserManager.allClassLeaders;
		this.colorMounted = true
		if (this.eventData) {
			this.settings.isFeatured = this.eventData.isFeatured;
			this.settings.calendarHex = this.eventData.calendarHex
				? this.eventData.calendarHex
				: store.state.accountInformation.appInformation.calendarColors.eventColor;
			this.settings.showOnWebsite = this.eventData.showOnWebsite != null ? this.eventData.showOnWebsite : true;	
			if (this.eventData.eventLeaders) {
				this.facultySwitch = true;
				this.settings.facultyLeaders = this.facultyLeaders.find(
					(e) => e.uid == this.eventData.eventLeaders[0]
				).uid;
			}
		} else if (this.classData) {
			this.settings.isFeatured = this.classData.isFeatured;
			this.settings.isPrivate =
				this.classData.isPrivate != null ? this.classData.isPrivate : false;
			this.settings.isLocked = this.classData.isLocked != null ? this.classData.isLocked : false;
			this.settings.requiredDocuments =
				this.classData.requiredDocuments != null
					? this.classData.requiredDocuments
					: true;
			this.settings.showOnWebsite = this.classData.showOnWebsite != null ? this.classData.showOnWebsite : true;		
			this.settings.isOnCalendar =
				this.classData.isOnCalendar != null ? this.classData.isOnCalendar : true;
			this.settings.calendarHex = this.classData.calendarHex
				? this.classData.calendarHex
				: store.state.accountInformation.appInformation.calendarColors
						.calendarColor;
			if (this.classData.classLeaders) {
				this.facultySwitch = true;
				this.settings.facultyLeaders = this.facultyLeaders.find(
					(e) => e.uid == this.classData.classLeaders[0]
				).uid;
			}
		}
		if (this.isNew) {
			this.settings.calendarHex =
				store.state.accountInformation.appInformation.calendarColors.eventColor;
		} else if (this.isNewClass) {
			this.settings.calendarHex =
				store.state.accountInformation.appInformation.calendarColors.classColor;
		}
		setTimeout(() => {
			const x = 1;
			this.x = x;
		}, 1500);
	},
};
</script>
