export default [
	{
		name: 'Every Day',
		value: 'everyDay',
	},
	{
		name: 'Every Week',
		value: 'everyWeek',
	},
	{
		name: 'Bi-Weekly',
		value: 'biWeekly',
	},
	{
		name: 'Every Month',
		value: 'everyMonth',
	},
	{
		name: 'Every Year',
		value: 'everyYear',
	},
];
